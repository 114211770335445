import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { UserService } from 'src/app/core/user.service';
import {
  ContainerStationInformation,
  Question,
  QuestionFieldType,
  StationInformation,
  StationInfoDrawerData,
  StationRosterMember,
} from 'src/models';
import { StationService } from 'src/app/core/station.service';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { TermsGeneric } from 'src/helpers';
import { catchError, ignoreElements, map } from 'rxjs/operators';
import { RouterNavigationService } from 'src/app/core/router-navigation.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { RosterComponent } from 'src/app/shared/roster/roster.component';

/**
 * Reusable component for the station information header.
 */
@Component({
  selector:
    'app-station-info-header[stationInformation][stationEditMode][viewNewStation]',
  templateUrl: './station-info-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    LoadingIndicatorComponent,
    RosterComponent,
    MatTooltipModule,
  ],
  styleUrls: ['./station-info-header.component.scss'],
})
export class StationInfoHeaderComponent implements OnInit, OnDestroy {
  /** Is component viewed in station edit mode? */
  @Input() stationEditMode!: boolean;

  private _stationInformation!:
    | StationInformation
    | ContainerStationInformation;

  /**
   * Station information object passed from parent.
   * @param value Station information object.
   */
  @Input() set stationInformation(
    value: StationInformation | ContainerStationInformation,
  ) {
    this._stationInformation = value;
    this.getStations();
  }

  /** Part of the new station ui. */
  @Input() viewNewStation = false;

  /** Is displayed in a new interface? */
  @Input() newInterfaceView = false;

  /** If current user is Architect or not. */
  @Input({ required: true }) isArchitect = false;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Station Admins. */
  stationAdmins$!: Observable<StationRosterMember[]>;

  /** Station Admins error. */
  stationAdminsError$!: Observable<unknown>;

  /** Station group name. */
  stationGroupName$!: Observable<string>;

  /** Station group name error. */
  stationGroupNameError$!: Observable<unknown>;

  /** Station name form. */
  stationNameForm: FormGroup<{
    /** Form control field name.*/
    name: FormControl<string | null>;
  }>;

  /** Field to change station name. */
  nameField!: Question;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Type of user looking at a document. */
  type: 'admin' | 'super' | 'worker';

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private stationService: StationService,
    public sidenavDrawerService: SidenavDrawerService,
    private routeService: RouterNavigationService,
  ) {
    this.type = this.userService.isAdmin() ? 'admin' : 'worker';

    this.stationNameForm = this.fb.group({
      name: [''],
    });
  }

  /** Set this.info. */
  ngOnInit(): void {
    this.nameField = {
      rithmId: '3j4k-3h2j-hj4j',
      prompt: this.stationName,
      questionType: QuestionFieldType.ShortText,
      isReadOnly: false,
      isRequired: true,
      isPrivate: false,
      children: [],
    };
    this.stationService.stationName$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        const stationName =
          data.length > 0 ? data : `Untitled ${TermsGeneric.Station.Single}`;
        this.stationNameForm.controls['name'].setValue(stationName);
      });
    this.getStations();
  }

  /**
   * Get stations.
   */
  getStations(): void {
    this.stationNameForm.controls['name'].setValue(this.stationName);
    if (this.viewNewStation) {
      this.getStationAdmins();
      this.getStationGroupName();
    }
  }

  /**
   * Get name of station from StationInformation based on type.
   * @returns The Station Name.
   */
  get stationName(): string {
    return 'stationName' in this._stationInformation
      ? this._stationInformation.stationName
      : this._stationInformation.name;
  }

  /**
   * Get the priority from StationInformation model.
   * @returns The Priority of station.
   */
  get priority(): number | null {
    return 'priority' in this._stationInformation
      ? this._stationInformation.priority
      : null;
  }

  /**
   * The id of the station or document.
   * @returns The id of the station or document.
   */
  get stationRithmId(): string {
    return 'rithmId' in this._stationInformation
      ? this._stationInformation.rithmId
      : this._stationInformation.stationRithmId;
  }

  /**
   * Toggles the open state of the drawer for station info.
   * @param drawerItem The drawer item to toggle.
   */
  toggleDrawer(drawerItem: 'stationInfo'): void {
    const dataInformationDrawer: StationInfoDrawerData = {
      stationRithmId: this.stationRithmId,
      stationName: this.stationName,
      editMode: this.stationEditMode,
      openedFromMap: false,
    };
    this.sidenavDrawerService.toggleDrawer(drawerItem, dataInformationDrawer);
    this.updateStationInfoDrawerName();
  }

  /**
   * Update InfoDrawer Station Name.
   */
  updateStationInfoDrawerName(): void {
    this.stationService.updatedStationNameText(
      this.stationNameForm.controls.name.value || '',
    );
  }

  /** Get Station Admins. */
  getStationAdmins(): void {
    this.stationAdmins$ = this.stationService.getStationAdmins(
      this.stationRithmId,
    );

    this.stationAdminsError$ = this.stationAdmins$.pipe(
      ignoreElements(),
      catchError((err: unknown) => of(err)),
    );
  }

  /** Get name first group of the station. */
  getStationGroupName(): void {
    this.stationGroupName$ = this.stationService
      .getStationGroupPath(this.stationRithmId)
      .pipe(map((element) => element.groupPath.at(0)?.name || ''));

    this.stationGroupNameError$ = this.stationGroupName$.pipe(
      ignoreElements(),
      catchError((err: unknown) => of(err)),
    );
  }

  /**
   * Go back to previous route.
   */
  goBack(): void {
    if (
      window.opener?.location?.pathname &&
      !this.routeService.getPreviousUrl
    ) {
      this.routeService.navigateByUrl(window.opener?.location?.pathname);
    } else {
      this.routeService.goBack();
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
