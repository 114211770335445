import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { first, Subject, takeUntil } from 'rxjs';
import {
  ContainerStationInformation,
  UserType,
  StationInformation,
  ContainerNameField,
  ContainerName,
  StationRosterMember,
  FieldNameSeparator,
} from 'src/models';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { StationService } from 'src/app/core/station.service';
import { ContainerService } from 'src/app/core/container.service';
import { ErrorService } from 'src/app/core/error.service';
import { UserService } from 'src/app/core/user.service';
import { TermsGeneric } from 'src/helpers';
import { PopupService } from 'src/app/core/popup.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';

/**
 * Reusable component for the document information header.
 */
@Component({
  selector: 'app-container-info-header[containerInformation]',
  templateUrl: './container-info-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    UserAvatarComponent,
    RouterModule,
    MatButtonModule,
    LoadingIndicatorComponent,
    MatTooltipModule,
  ],
  styleUrls: ['./container-info-header.component.scss'],
})
export class ContainerInfoHeaderComponent implements OnInit, OnDestroy {
  /** Type of user looking at a document. */
  @Input() userType!: UserType;

  /** Document information object passed from parent. */
  @Input() containerInformation!:
    | ContainerStationInformation
    | StationInformation;

  /** The Document how widget. */
  @Input() isWidget = false;

  /** The Current Document Rithm Id. */
  @Input() containerRithmId = '';

  /** The Current Station Rithm Id. */
  @Input() stationRithmId = '';

  /** If current user is Architect or not. */
  @Input({ required: true }) isArchitect = false;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** The different options for the separator value. */
  fieldNameSeparatorOptions = FieldNameSeparator;

  /**
   * Station or Document looking at document header.
   * @returns Station edit mode or document mode. TRUE if station mode and FALSE if document mode.
   */
  get isStation(): boolean {
    return !('containerName' in this.containerInformation);
  }

  /**
   * The name for station show in document how widget.
   * @returns The name of station.
   */
  get stationNameDocument(): string {
    return 'stationName' in this.containerInformation
      ? this.containerInformation.stationName
      : '';
  }

  /**
   * Is the current user an owner or an admin for this station.
   * @returns Validate if user is owner or admin of current station.
   */
  get isUserAdminOrOwner(): boolean {
    const ownerDocument = this.containerInformation.stationOwners?.find(
      (owner) => this.userService.user().rithmId === owner.rithmId,
    );
    return !!ownerDocument || this.userService.isAdmin();
  }

  /**
   * Checking if the current user is in workers.
   * @returns Is in workers.
   */
  get isUserInWorkers(): boolean {
    return (
      this.containerInformation.workers?.find(
        (workers) => this.userService.user().rithmId === workers.rithmId,
      ) !== undefined
    );
  }

  /**
   * Is the current user an owner or an admin for this station.
   * @returns Validate if user is owner or admin of current station.
   */
  get currentAssignedUserDocument(): StationRosterMember {
    const user: StationRosterMember = {
      rithmId: '',
      firstName: '',
      lastName: ' ',
      email: '',
      isWorker: true,
      isOwner: false,
    };
    return 'currentAssignedUser' in this.containerInformation
      ? this.containerInformation.currentAssignedUser
      : user;
  }

  /** Subject for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Document name form. */
  documentNameForm: FormGroup<{
    /** Name for form control.*/
    name: FormControl<string | null>;
  }>;

  /** Enum for all types of a user. */
  userTypeEnum = UserType;

  /** Current document name. */
  containerName = '';

  /** Fields appended to the document name. */
  appendedDocumentName = '';

  /** Whether the Station allows edit document name or not. */
  isDocumentNameEditable!: boolean;

  /** Loading indicator to assign user. */
  loadingAssignUser = false;

  /** Variable to show if error message should be displayed. */
  displayAssignUserError = false;

  /** Document Appended Fields. */
  documentAppendedFields: ContainerNameField[] = [];

  /** Container label. */
  containerLabel = '';

  constructor(
    private fb: FormBuilder,
    public sidenavDrawerService: SidenavDrawerService,
    private stationService: StationService,
    private containerService: ContainerService,
    private errorService: ErrorService,
    private userService: UserService,
    private popupService: PopupService,
  ) {
    this.documentNameForm = fb.group({
      name: [''],
    });
  }

  /**
   * Disable document input element in station edit mode.
   */
  ngOnInit(): void {
    this.subscribeContainerStationNameFields$();
    this.templateUpdateContainerLabel$();
    this.getContainerLabel();
    this.subscribeContainerLabelText$();

    this.isStation
      ? this.documentNameForm.disable()
      : this.documentNameForm.enable();
    if (!this.isStation) {
      this.getContainerName();
    } else {
      this.getAppendedFieldsOnDocumentName(this.stationRithmId);
    }
    this.getStatusContainerEditable();
  }

  /**
   * Subject for updated container label.
   */
  private templateUpdateContainerLabel$(): void {
    this.stationService.containerLabel$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stationRithmId) => {
        if (this.stationRithmId === stationRithmId) {
          this.getContainerLabel();
        }
      });
  }

  /**
   * Get the container label of the current station.
   *
   */
  private getContainerLabel(): void {
    this.stationService
      .getContainerLabel(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (containerLabel) => {
          this.containerLabel =
            containerLabel === TermsGeneric.Container.Single
              ? 'Untitled ' + containerLabel
              : containerLabel;
          this.containerService.containerLabelText$.next({
            rithmId: this.stationRithmId,
            value: this.containerLabel,
          });
        },
        error: () => {
          this.popupService.notify(
            `Error ${TermsGeneric.Container.Single} Label.`,
            true,
          );
        },
      });
  }

  /**
   * Listen to the ContainerLabelText.
   */
  private subscribeContainerLabelText$(): void {
    this.containerService.containerLabelText$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((containerLabel) => {
        if (containerLabel.rithmId === this.stationRithmId) {
          this.containerLabel = containerLabel.value;
        }
      });
  }

  /**
   * Get is user is admin or worker or owner in document.
   * @returns If is admin or worker or owner in document.
   */
  isAdminOrWorkerOrOwner(): boolean {
    return this.userService.isAdmin()
      ? true
      : this.containerInformation.stationOwners?.find(
            (owner) => owner.rithmId === this.userService.user().rithmId,
          )
        ? true
        : !!this.containerInformation.workers?.find(
            (worker) => worker.rithmId === this.userService.user().rithmId,
          );
  }

  /** Get Container Appended Fields from Behaviour Subject. */
  private subscribeContainerStationNameFields$() {
    this.stationService.containerStationNameFields$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((appendedFields) => {
        this.documentAppendedFields = appendedFields;
      });
  }

  /**
   * Toggles the open state of the drawer for document info.
   * @param drawerItem The drawer item to toggle.
   */
  toggleDrawer(drawerItem: 'containerInfo'): void {
    this.sidenavDrawerService.toggleDrawer(drawerItem, {
      stationRithmId: this.stationRithmId,
      isStation: this.isStation,
      isUserAdminOrOwner: this.isUserAdminOrOwner,
      containerRithmId: this.containerRithmId,
    });
  }

  /**
   * Get document name.
   */
  private getContainerName(): void {
    this.containerService
      .getContainerName(this.containerRithmId)
      .pipe(first())
      .subscribe({
        next: (containerName) => {
          this.documentNameForm.controls.name.setValue(containerName.baseName);
          this.containerName = containerName.baseName;
          this.appendedDocumentName = this.formatAppendedName(
            containerName.appendedName,
          );
          containerName.appendedName = this.appendedDocumentName;
          this.containerService.updateContainerNameBS(containerName);
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Give a properly format to the appendedName.
   * @param appendedName Unformatted appendedName.
   * @returns Formatted appendedName.
   */
  private formatAppendedName(appendedName: string): string {
    const formatted: string[] = [];
    const separatedChips: string[] = appendedName.split('/');
    //Separate each chip added to appended name
    separatedChips.forEach((element) => {
      //verify each element is in a readable format and added to the final form
      if (!element.includes('|') && !element.includes(':')) {
        formatted.push(element);
      } else {
        // if an element contain rithmsId or it is not readable then it will be splitted till become readable
        const items: string[] = [];
        if (element.includes('|')) {
          const barItems = element.split('|');
          barItems.forEach((item) => {
            items.push(item.split(':')[1]);
          });
          formatted.push(items.toString().replace(/(,)/g, ' | '));
        } else {
          formatted.push(element.split(':')[1]);
        }
      }
    });
    //finally we rebuilt the string to make it readable again.
    return formatted.toString().replace(/(,)/g, ' / ');
  }

  /**
   * Get appended fields to document name template.
   * @param stationId  The id of station.
   */
  getAppendedFieldsOnDocumentName(stationId: string): void {
    this.stationService
      .getContainerNameTemplate(stationId)
      .pipe(first())
      .subscribe({
        next: (appendedFields) => {
          if (appendedFields) {
            this.documentAppendedFields = appendedFields;
            // Can be removed below if block once we have a fresh data.
            if (
              this.documentAppendedFields.length > 1 &&
              this.documentAppendedFields.some(
                (field) => !field.questionRithmId,
              )
            ) {
              const separator = this.documentAppendedFields[1].prompt;
              this.documentAppendedFields = this.documentAppendedFields.filter(
                (field) => field.questionRithmId,
              );
              this.documentAppendedFields.map((appendField) => {
                appendField.prompt = appendField.prompt + ' ' + separator;
              });
            }
            this.stationService.updateContainerStationNameFields(
              this.documentAppendedFields,
            );
          }
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get the station document name editable status.
   *
   */
  private getStatusContainerEditable(): void {
    this.stationService
      .getStatusContainerEditable(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (editableStatus) => {
          this.isDocumentNameEditable = editableStatus;
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Remove an appended field from document field names.
   * @param index The current index to remove from appendedFields.
   */
  removeAppendedFieldFromDocumentName(index: number): void {
    const separatorsList = Object.values(this.fieldNameSeparatorOptions);
    const separatorText =
      this.documentAppendedFields[index].prompt[
        this.documentAppendedFields[index].prompt.trimEnd().length - 1
      ];
    separatorsList.map((separator) => {
      if (separator === separatorText) {
        this.documentAppendedFields[index].prompt = this.documentAppendedFields[
          index
        ].prompt
          .replace(separator, '')
          .trimEnd();
      }
    });
    this.documentAppendedFields.splice(index, 1);
    this.stationService.updateContainerStationNameFields(
      this.documentAppendedFields,
    );
    this.stationService.touchStationForm();
  }

  /**
   * Update the Document Name Behavior Subject.
   */
  updateContainerNameBS(): void {
    const baseName = this.documentNameForm.controls['name'];
    const containerName: ContainerName = {
      baseName:
        baseName.value?.trim() || `Untitled ${TermsGeneric.Container.Single}`,
      appendedName: this.appendedDocumentName,
    };

    if (!this.isWidget) {
      this.containerService.updateContainerNameBS(containerName);
    }
    if (!baseName.value?.trim()) {
      baseName.setValue(baseName.value?.trim() || '');
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stationService.containerStationNameFields$.next([]);
  }
}
